

















import { Vue, Component } from "vue-property-decorator";
import adminService from "@/services/AdminService";

@Component
export default class EmissionTypes extends Vue {
  public emissionTypes: any = [];

  private async getEmissionTypes() {
    this.emissionTypes = await adminService.getEmissionTypes();
  }

  public async mounted() {
    await this.getEmissionTypes();
  }
}
